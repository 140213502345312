@media screen and (min-width: 899px) {
    .feedback-carousel .img-container{
        float: left;
    }
}
@media screen and (max-width: 899px) {
    .headerShape::before {
        height: 72px;
        bottom: -72px;
    }

    .herosection {
        padding-top: 80px;
    }

    .hero-heading {
        font-size: 40px;
    }

    .footershape {
        height: 82px;
        margin-bottom: 82px;
    }
    .goto-top{
        right: 20px;
        bottom: 20px;
    }
    .page-banner .shape{
        height: 34px;
    }
    .page-banner{
        padding: 130px 0px 100px 0px;
    }
    .section-heading {
        font-size: 26px;
    }
    .feedback-carousel .img-container{
        margin: auto;
        margin-bottom: 20px;
    }
}