.loader_main {
    background-color: rgba(0, 0, 0, 0.7);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 80px;
    height: 80px;
    border: transparent solid 3px;
    border-left-color: #fff;
    border-top-color: #fff;
    border-radius: 50%;
    position: relative;
    animation: loading 2s infinite;
    overflow: hidden;
}

.loader::after {
    position: absolute;
    content: '';
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    border: transparent solid 3px;
    border-left-color: #fff;
    border-top-color: #fff;
    border-radius: 50%;
    animation: loading 2s infinite linear reverse;
}

.loader::before {
    position: absolute;
    content: '';
    top: 25px;
    left: 25px;
    bottom: 25px;
    right: 25px;
    border: transparent solid 3px;
    border-left-color: #fff;
    border-top-color: #fff;
    border-radius: 50%;
    z-index: 1;
    animation: loading 1s infinite;
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}