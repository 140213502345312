@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600;700;800&family=Roboto:ital@0;1&display=swap');

body {
    font-family: 'League Spartan', sans-serif;
    /* color: #061a3a; */
}

.bg-light {
    background-color: #F2F9FF !important;
}
.text-justify{
    text-align: justify;
}
.list-style-none {
    list-style: none;
    padding: 0;
}

.fs-20 {
    font-size: 20px !important;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.herosection {
    background-color: #1162aa;
    padding-top: 143px;
    color: #fff;
    z-index: 0;
    background-image: url('../src/assets/images/hero_bg.PNG');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}
.herosection::after{
    position: absolute;
    content: '';
    background-color: rgb(0 26 79 / 79%);
    inset: 0;
    z-index: -1;
}

.hero-heading {
    font-size: 50px;
    line-height: 66px;
    font-weight: 800;
    margin-bottom: 23px;
    cursor: context-menu;
}

.section-heading {
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;
    text-transform: capitalize;
}
.Water_Do_For_You .section-heading{
    color: #1162aa;
}
.trusted-item .step-number{
    background-color: #1162aa;
    padding: 10px;
    color: #fff;
    border-radius: 0px 0px 5px 0px;
    top: 0;
    left: 0;
}
.trusted-item .icon {
    font-size: 60px;
    color: #00d1f9;
}

.trusted-item .card-tile {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
}
.Water_do_for_img{
    border: 5px solid rgb(0 26 79 / 79%);
    border-radius: 10px;
    padding: 15px;
}
.discription {
    color: #808080;
    font-size: 20px;
}

.homeabout .about-more {
    background-color: #00d1f9;
    color: #fff;
    padding: 20px 40px;
    text-decoration: none;
    font-size: 20px;
    opacity: 0.8;
}

.homeabout .about-more:hover {
    opacity: 1;
}

.title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 14px;
    transition-duration: .3s;
    cursor: pointer;
}

.title:hover {
    color: #00d1f9;
}

.protect-family .icon-box {
    background-color: #00d1f9;
    width: 100px;
    height: 100px;
    color: #fff;
    font-size: 44px;
    transition-duration: .3s;
}

.protect-family .icon-box:hover {
    background-color: #F2F9FF;
    color: #00d1f9;
}

.filtering-system {
    background-image: url('./assets/images/bg-layer.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
}

.filtering-system .icon-container {
    width: 60px;
    height: 60px;
    background-color: #00d1f9;
    color: #fff;
    float: left;
    transition: .5s;
    border: 2px solid #00d1f9;
}

.filtering-system .icon-container:hover {
    background-color: #fff;
    color: #00d1f9;
}

.fs-30 {
    font-size: 30px !important;
}
.quality-bottle .bottle-img{
    transition: all .5s ease-in-out;
    margin-bottom: 20px;
}
.quality-bottle .bottle-img:hover{
    transform: scale(1.1); 
}
.myimg-border{
    border: 10px solid #fff;
}
.price{
    color: #00d1f9;
}
.feedback-carousel .img-container{
    width: 100%;
    height: 150px;
    margin: auto;
    display: flex;
    align-items: center;
    float: none !important;
}
.feedback-carousel .img-container img{
    margin: auto;
}
.feedback-carousel .star-icon{
    color: #00d1f9;
    font-size: 20px;
}
.carousel-indicators [data-bs-target]{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #1162aa;
}
.carousel-indicators .active{
    background-color: #00d1f9;
}
.carousel-indicators{
    bottom: -20px;
}
.home-delivery{
    background-image: url('./assets/images/delivery-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #1162aa;
}
.check-icon{
    background:rgba(0,0,0,0.5);
    padding: 4px;
    border-radius: 50%;
}

/* page Banner */
.page-banner{
    background-image: url('./assets/images/page-banner-bg.jpg');
    position: relative;
    width: 100%;
    padding: 150px 0px 100px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}
.page-banner .shape{
    background-image: url('./assets/images/banner-shap.png');
    background-size: cover;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 68px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.ABOUT_US_PAGE .page-banner .shape{
    background-image: url('./assets/images/about-banner-shap.png');
}
.page-banner::after{
    position: absolute;
    content: '';
    background: #00d1f9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    z-index: -1;
}
/* page Banner */

.submit-btn{
    font-size: 18px !important;
    text-transform: capitalize !important;
    background-color: #00d1f9 !important;
}
.cont-info .icon-container{
    background-color: #EBF3FA;
    color: #00d1f9;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 20px;
}
.cont-info .icon-container .icon{
    font-size: 28px !important;
}
.team-member .member-img{
    transition: all 500ms ease;
}
.team-member .member-img:hover{
    backdrop-filter: blur(6px);
}

